@import '../../lib/variables.scss';

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    row-gap: 1rem;
    padding: 0.8rem 0;
    border: 1px solid $light_green_stroke;
    border-radius: 10px;
    height: auto;
    background-color: $light_green;
    width: 95%;
    @media screen and (min-width: 1182px) {
        width: 50%;
    }
}
.container_white_bg {
    @extend .container;
    background-color: #ffffff;
}
.success_title {
    font-weight: 700;
    font-size: 1.7rem;
    color: $dark_green;
}
.success_text {
    font-weight: 400;
    font-size: 1rem;
    color: $body;
    text-align: center;
}
.divider {
    border-top: 1px solid $light_green_stroke;
    width: 100%;
}
.booking {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 0.8rem;
    width: 100%;
    padding: 0 1.5rem;
    @media screen and (min-width: 1182px) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}
.booking_info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 0 1.5rem;
    @media screen and (min-width: 1182px) {
        align-items: flex-start;
    }
}
.info_title {
    text-transform: uppercase;
    color: $body;
    font-weight: 500;
    font-size: 14px;
}
.info_text {
    color: $dark_grey;
    font-weight: 600;
    font-size: 20px;
    margin-top: 8px;
}
.button {
    width: auto;
    border-radius: 50px;
    border: none;
    background: $blue_to_green_gradient;
    color: #ffffff;
    padding: 10px 20px;
    font-size: 1em;
    font-weight: 600;
}
