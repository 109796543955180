@import 'lib/variables.scss';

* {
    margin: 0;
    padding: 0;
    font-family: $font_family;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.modal {
    width: 20em;
    background-color: transparent;
    position: absolute;
    flex-direction: column;
    align-items: center;
    bottom: 5px;
    left: 42%;
}
h1 {
    font-size: 2rem;
}

h2 {
    font-size: 1.4rem;
}

h3 {
    font-size: 1.2rem;
}

h4 {
    font-size: 24px;
}
p {
    font-size: 16px;
}

a {
    text-decoration: none;
}

@media screen and (min-width: 768px) {
    h1 {
        font-size: 34px;
    }

    h2 {
        font-size: 2rem;
    }

    h3 {
        font-size: 1.5rem;
    }

    h4 {
        font-size: 1.3rem;
    }
    h5 {
        font-size: 1.1rem;
        font-weight: 500;
    }
}
