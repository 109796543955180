@import '../../lib/variables.scss';

.checkin_success_text_container {
    margin-left: 10px;
    font-size: 1.3rem;
    color: $dark_grey;
    @media only screen and (min-width: 768px) {
        font-size: 2rem;
    }
}
.checkin_success_text {
    font-weight: 600;
    font-size: 1.3rem;
    color: $dark_green;
}
.row {
    display: flex;
    justify-content: center;
    gap: 3rem;
    align-items: center;
    width: 50%;
    margin: 1.5rem 0;
}
.qr_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 2% 10%;
    text-align: center;
}
.steps_title {
    color: #ffffff;
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 1rem;
}
.qr_title {
    color: $title;
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 1rem;
}
.booking_info {
    margin-bottom: 4px;
}
.curved_arrow {
    position: absolute;
    height: 180px;
    left: 28%;
    top: 60%;
}

.checkin_success_steps_title {
    margin: 1rem 0 0 0;
    font-size: 1rem;
    text-align: center;
    color: $dark_grey;
    @media only screen and (min-width: 768px) {
        font-size: 1.2rem;
        margin: 1rem 0 0 0;
    }
}

.icons_row {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.icons_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
    font-size: 1rem;
    width: 90%;
    color: $dark_grey;
    @media only screen and (min-width: 768px) {
        font-size: 1.5rem;
    }
}

.icons_container {
    width: 9rem;
    height: 9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-radius: 1.5rem;
    @media only screen and (min-width: 768px) {
        width: 12rem;
        height: 12rem;
        border-radius: 2rem;
    }
}

.icon_image {
    height: 70%;
    width: 70%;
}

.new_checkin_button {
    padding: 1rem 1.7rem;
    border-radius: 3rem;
    align-self: center;
    font-weight: bold;
    font-size: 1rem;
    background: $green;
    border: 1px solid $green;
    color: $off_white;

    &:active {
        opacity: 50%;
    }

    @media only screen and (min-width: 768px) {
        padding: 1rem 1.7rem;
        border-radius: 3rem;
        font-size: 1.8rem;
    }
}

.checkin_complete_message_text {
    line-height: 2rem;
    font-size: 1rem;
    text-align: center;
    color: white;
}
