@import '../../lib/variables.scss';

.container_no_shadow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 23px;
    align-content: center;
    width: auto;
    min-width: 360px;
    border: 1px solid $grey;
    border-radius: 10px;
    cursor: pointer;
    padding: 15px 20px;
    margin: 0.4rem;
    height: auto;
    @media screen and (min-width: 1181px) {
        margin: 0.7rem;
        padding: 32px;
        row-gap: 25px;
        min-width: 480px;
    }
}
.light {
    @extend .container_no_shadow;
    color: #ffffff;
    margin: 0;
}

.container_shadow {
    @extend .container_no_shadow;
    border: 2px solid $blue;
    box-shadow: 1px 1px 20px 2px rgba(39, 148, 220, 0.65);
    -webkit-box-shadow: 1px 1px 20px 2px rgba(39, 148, 220, 0.65);
    -moz-box-shadow: 1px 1px 20px 2px rgba(39, 148, 220, 0.65);
    @media screen and (min-width: 1182px) {
        box-shadow: 1px 1px 35px 2px rgba(39, 148, 220, 0.65);
        -webkit-box-shadow: 1px 1px 35px 2px rgba(39, 148, 220, 0.65);
        -moz-box-shadow: 1px 1px 35px 2px rgba(39, 148, 220, 0.65);
    }
}
.container_disabled {
    @extend .container_no_shadow;
    border: 1px solid $grey;
    cursor: default;
}
.booking_title {
    margin-left: 3px;
}
.time_location_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 0.3rem;
    align-items: flex-start;
    width: auto;
    @media screen and (min-width: 1024px) {
        flex-direction: row;
        column-gap: 0.6rem;
        align-items: center;
    }
}
.row_container {
    display: flex;
    justify-content: space-between;
    column-gap: 0.6rem;
    align-items: center;
    width: auto;
    height: 30%;
}
.rounded_box {
    display: flex;
    width: auto;
    column-gap: 4px;
    justify-content: flex-start;
    align-items: center;
    border-radius: 50px;
    padding: 8px 14px;
    border: 1px solid $grey;
    @media screen and (min-width: 1182px) {
        padding: 12px 14px;
    }
}
.rounded_box_blue {
    @extend .rounded_box;
    border: none;
    background: linear-gradient(90deg, rgba(39, 148, 220, 0.2) 0%, rgba(34, 187, 196, 0.2) 94.92%);
}
.rounded_box p {
    font-size: 1rem;
}
.doctor_container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 0.6rem;
    width: 60%;
}
.avatar {
    border-radius: 5px;
    overflow: hidden;
    height: 100%;
}
.name {
    font-weight: 500;
}
.title {
    font-weight: 400;
    font-size: 14px;
}
.selectButton {
    width: 40%;
    height: 90%;
    border-radius: 50px;
    border: 1px solid $blue;
    background: white;
    color: $blue;
    padding: 10px 5px;
    font-size: 1em;
    font-weight: 600;
}
.checkInButton {
    @extend .selectButton;
    border: none;
    background: $blue_to_green_gradient;
    color: #ffffff;
}
