@import '../../lib/variables.scss';

.welcomeTextContainer {
    position: absolute;
    top: 15%;
    @media screen and (min-width: 768px) {
        top: 20%;
    }
}
.welcomeTextContArabic {
    position: absolute;
    top: 15%;
}

.screensaverWelcomeText {
    text-align: center;
    font-size: 2.6rem;
    font-weight: 700;
    text-transform: uppercase;
    color: #ffffff;
}

.screensaverInstructions {
    margin: 8px 0 0 0;
    font-weight: 400;
    font-size: 22px;
    color: #ffffff;
    text-align: center;
}

.screensaverCircleOuter {
    position: absolute;
    top: 30%;
    width: 326px;
    height: 326px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.25);
    border-radius: 50%;
}

.screensaverCircleInner {
    width: 196px;
    height: 196px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.3);
    font-weight: bold;
    font-size: 1.5rem;
    text-transform: uppercase;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.screensaverCircleInner p {
    font-size: 1rem;
}

.screensaverPointingHandWrapper {
    position: absolute;
    top: 10rem;
}

.screensaverPointingHand {
    position: absolute;
    top: -0.2rem;
    left: -3.5rem;
    z-index: 3;
    animation: pulseSize 2.5s infinite;
    filter: drop-shadow(0px 5px 15px rgba(100, 100, 111, 0.2));
    height: 10rem;
}

.screensaverPointingHandWrapperPulse {
    width: 1px;
    height: 1px;
    border-radius: 50%;
    box-shadow: 0 0 0 #ff4090;
    animation: pulseColor 2.5s infinite;
}

.qrContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    inset: 45% 5% auto auto;
    padding: 12px;
    background-color: white;
    filter: drop-shadow(0px 5px 15px rgba(100, 100, 111, 0.2));
    border-radius: 25px;
}

.screensaverQRCode {
    width: 8rem;
    height: 8rem;
}

@keyframes pulseColor {
    0% {
        box-shadow: 0 0 0 0 rgba(#ff4090, 0.7);
    }
    50% {
        box-shadow: 0 0 0 160px rgba(#ff4090, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(#ff4090, 0);
    }
}

@keyframes pulseSize {
    0% {
        transform: scale(1);
    }
    70% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

.screensaverClockAndLanguageBar {
    position: absolute;
    bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 4rem);
}
@media screen and (max-width: 450px) {
    .screensaverClockAndLanguageBar {
        display: flex;
        flex-direction: column;
        gap: 1em;
    }
}
@media screen and (min-width: 1470px) {
    .screensaverCircleOuter {
        margin-top: 5%;
        width: 426;
        height: 426;
    }
    .screensaverCircleInner {
        width: 210px;
        height: 210px;
    }
    .screensaverCircleInner p {
        font-size: 1.5rem;
    }
    .screensaverPointingHand {
        position: absolute;
        top: 1rem;
        left: -3.5rem;
        z-index: 3;
        animation: pulseSize 2.5s infinite;
        filter: drop-shadow(0px 5px 15px rgba(100, 100, 111, 0.2));
        height: 10rem;
    }
}
@media screen and (min-width: 1600px) {
    .screensaverCircleOuter {
        width: 420px;
        height: 420px;
    }
    .screensaverCircleInner {
        width: 250px;
        height: 250px;
    }
    .screensaverCircleInner p {
        font-size: 2rem;
    }
    .screensaverPointingHand {
        position: absolute;
        top: 2rem;
        left: -3.5rem;
        z-index: 3;
        animation: pulseSize 2.5s infinite;
        filter: drop-shadow(0px 5px 15px rgba(100, 100, 111, 0.2));
        height: 10rem;
    }
}
@media screen and (min-width: 1800px) {
    .screensaverCircleOuter {
        width: 520px;
        height: 520px;
    }
    .screensaverCircleInner {
        width: 310px;
        height: 310px;
    }
    .screensaverCircleInner p {
        font-size: 2rem;
    }
    .screensaverPointingHand {
        position: absolute;
        top: 2rem;
        left: -3.5rem;
        z-index: 3;
        animation: pulseSize 2.5s infinite;
        filter: drop-shadow(0px 5px 15px rgba(100, 100, 111, 0.2));
        height: 10rem;
    }
}
