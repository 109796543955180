.container {
    display: flex;
    column-gap: 20px;
    justify-content: center;
    align-items: center;
    max-width: 360px;
}
.container_col {
    @extend .container;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    bottom: 25px;
}
@media screen and (min-width: 768px) {
    .container {
        display: flex;
        position: absolute;
        bottom: 40px;
        column-gap: 20px;
        justify-content: center;
        align-items: center;
        max-width: 500px;
    }
}
