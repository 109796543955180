@import './font';

// Colors
$blue_to_green_gradient: linear-gradient(90.33deg, #2794dc 0%, #22bbc4 94.92%);
$transparent_blue_to_green_gradient: linear-gradient(
    90.33deg,
    rgba(#2794dc, 0.5) 0%,
    rgba(#22bbc4, 0.5) 94.92%
);
$transparent_white: rgba(#ffffff, 0.2);
$grey: #d9d9d9;
$dark_grey: #000000;
$light_grey: #e5e5e5;
$light_text: rgba(0, 0, 0, 0.6);
$bg_color: #f8f8f8;
$green: #22c493;
$blue: #2794dc;
$off_white: rgba(#ffffff, 0.8);
$dark_green: #0fba0c;
$red: #e31c40;
$light_red: rgba(227, 28, 64, 0.1);
$light_green: #f0f8f0;
$light_green_stroke: #e4ebeb;
$body: #555555;
$title: #090909;
