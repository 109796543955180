@import '../../lib/variables.scss';

h2 {
    padding: 0;
    margin: 0;
}
.payment_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    row-gap: 20px;
    @media screen and (min-width: 1024) {
    }
}
.info_text_container {
    padding: 10px;
    border-radius: 5px;
    background-color: #d7f1f2;
}
.swish_qr_container {
    border: 0.5px solid $light_grey;
    border-radius: 5px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0px;
}
.swishQr {
    width: 200px;
    object-fit: contain;
    text-align: center;
    margin-top: 10px;
}
.error_container {
    padding-top: 4rem;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    align-items: center;
    width: 90%;
}
.error_text {
    font-size: 1.2rem;
    color: $red;
}
.success_text_container {
    padding-top: 4rem;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    align-items: center;
    width: 90%;
}
.success_text {
    font-size: 1.2rem;
    margin-left: 10px;
}
.button_container {
    width: 100%;
    display: flex;
    gap: 30px;
    justify-content: center;
    align-items: center;
}
