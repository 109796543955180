@import '../../lib/variables.scss';

.numpad {
    margin: 1.8rem auto;
    position: relative;
    color: $dark_grey;
    user-select: none;
}

.numpadNumberGrid {
    display: grid;
    grid-template-rows: repeat(4, 4rem);
    grid-template-columns: repeat(3, 4rem);
    gap: 1.2rem;
}

.digit {
    background-color: white;
    font-weight: bold;
    font-size: 2rem;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    border: solid 1px transparent;
    box-shadow: 1px 130px 1px #ffffff inset;
    background: $blue_to_green_gradient;
    color: $dark_grey;
    &:active {
        box-shadow: none;
        color: white;
    }
    &:last-child {
        grid-column-end: 3;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
}

.backspace {
    font-size: 2rem;
    position: absolute;
    top: 0.8rem;
    right: -4.5rem;
    width: 100%;
    max-width: 3rem;
    padding: 0;
    height: 2rem;
    clip-path: polygon(0% 50%, 28% 0%, 95% 0%, 100% 0%, 100% 100%, 100% 100%, 28% 100%, 0 50%);
    color: white;
    border: none;
    &:active {
        background: $transparent_blue_to_green_gradient;
    }
    &:disabled {
        background: #e5e5e5;
        pointer-events: none;
    }
    user-select: none;
}

.backspaceText {
    margin-top: -0.5rem;
    margin-left: 1.2rem;
    display: flex;
}

.backspaceActive {
    background: $blue_to_green_gradient;
}
@media screen and (min-width: 1600px) {
    .numpad {
        margin: 2rem auto;
    }
    .numpadNumberGrid {
        display: grid;
        grid-template-rows: repeat(4, 5.5rem);
        grid-template-columns: repeat(3, 5.5rem);
        gap: 1.2rem;
    }
    .digit {
        font-size: 2.5rem;
    }
}
@media screen and (min-width: 1920px) {
    .numpadNumberGrid {
        display: grid;
        grid-template-rows: repeat(4, 6rem);
        grid-template-columns: repeat(3, 6rem);
        gap: 1.2rem;
    }
    .digit {
        font-size: 2.5rem;
    }
}
