@import '../../lib/variables.scss';

.box {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    padding: 8px 18px;
    border-radius: 10px;
    font-size: 1rem;
    width: 95%;
    border: 1px solid $grey;
    @media screen and (min-width: 1182px) {
        min-width: 40%;
        width: auto;
    }
}

@media screen and (min-width: 786px) {
    .box {
        font-size: 1.2rem;
    }
}
.transparent {
    @extend .box;
    border: none;
}
.active {
    @extend .box;
    border: 1px solid $green;
    border-radius: 10px;
    cursor: pointer;
    padding: 18px;
    color: #000000;
    font-size: 1rem;
}

.disabled {
    @extend .active;
    border: 1px solid $grey;
    cursor: default;
}

.success {
    @extend .box;
    flex-direction: column;
    color: #22c493;
    background-color: rgba(34, 196, 147, 0.1);
    border: 0;
    justify-content: center;
}

.success_start {
    @extend .success;
    justify-content: flex-start;
}

.grey {
    @extend .box;
    background-color: $bg_color;
    padding: 10px 10px 18px;
    border: 0;
}

.error {
    @extend .box;
    background-color: $light_red;
    color: $red;
    border: 0;
    justify-content: center;
}
