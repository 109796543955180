@import '../../lib/variables';

.button {
    padding: 15px 25px;
    border: 0;
    border-radius: 50px;
    min-width: 150px;
    width: auto;
    background: $blue_to_green_gradient;
    user-select: none;
    &:disabled {
        background: $light_grey;
        color: $grey;
    }
    @media screen and (min-width: 768px) {
        padding: 30px 36px;
        min-width: 190px;
    }
}
.transparent {
    @extend .button;
    background: white;
    border: 1px solid $grey;
}
.gradient_text {
    background: $blue_to_green_gradient;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 22px;
    font-weight: 600;
}
.text {
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    @media screen and (min-width: 1024px) {
        font-size: 22px;
    }
}
