@import '../../lib/variables.scss';

.card {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 95%;
    align-items: center;
    row-gap: 20px;
    width: 100%;
    color: $dark_grey;
    background-color: transparent;
    font-size: 1rem;
    // @media only screen and (min-width: 599px) {
    //     font-size: 1.2rem;
    //     width: 60%;
    // }
    // @media only screen and (min-width: 1000px) {
    //     width: 55%;
    // }
    // @media only screen and (min-width: 1180px) {
    //     width: 45%;
    // }
    // @media only screen and (min-width: 1400px) {
    //     width: 30%;
    // }
}

.small_card {
    @extend .card;
    @media only screen and (min-width: 599px) {
        width: auto;
    }
    @media only screen and (min-width: 1000px) {
        width: auto;
    }
    @media only screen and (min-width: 1400px) {
        width: auto;
    }
}

/*.card_with_border {
    @extend .card;
    border: 1px solid $light_grey;
}
.card_with_shadow {
    @extend .card;
    box-shadow: 0px 1px 11px 5px rgba(224, 228, 230, 1);
    -webkit-box-shadow: 0px 1px 11px 5px rgba(224, 228, 230, 1);
    -moz-box-shadow: 0px 1px 11px 5px rgba(224, 228, 230, 1);
}*/
