@import '../../lib/variables.scss';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    min-height: calc(100vh - 5.5rem);
    justify-content: flex-start;
    padding-top: 10px;
    @media screen and (min-width: 1182px) {
        padding-top: 20px;
    }
}
.container_gradient_bg {
    @extend .container;
    background: $blue_to_green_gradient;
}
